import React from "react"
import { graphql } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { Link } from "gatsby-plugin-intl"
import { FaFacebook } from "react-icons/fa"

// Elements
import Layout from "../elements/layout"
import SEO from "../elements/seo"
import Slider from "../elements/slider"
import Events from "../elements/events"

export const query = graphql`
  query($slug: String!) {
    contentfulEventPost(slug: { eq: $slug }) {
      title
      subtitle
      description
      body {
        json
      }
      image {
        file {
          url
        }
      }
    }
    file(relativePath: { eq: "sliderImage10.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
  }
`
const Event = props => {
  const options = {
    renderNode: {
      "embedded-asset-block": () => {
        return (
          <img
            alt={"alt"}
            src={props.data.contentfulEventPost.image.file.url}
          />
        )
      },
    },
  }
  return (
    <Layout>
      <SEO
        title={props.data.contentfulEventPost.title}
        keywords={[
          `new macau casino, casino, tete, mozambique, events, party, club, nightclub, restaurant, bar, buffet, restaurant ${props.data.contentfulEventPost.title}`,
        ]}
        description={props.data.contentfulEventPost.subtitle}
      />
      <Slider
        sliderImage={props.data.file.childImageSharp.fluid}
        sliderTitle={props.data.contentfulEventPost.title}
        sliderSubtitle={props.data.contentfulEventPost.subtitle}
        objectPosition="65% 50%"
      />
      <div className="eventTemplate">
        {documentToReactComponents(
          props.data.contentfulEventPost.body.json,
          options
        )}
        <div className="eventTemplate__buttons">
          <Link to="/events" className="eventTemplate__button1">
            View All Events
          </Link>
          <a href="https://www.facebook.com/pg/newmacaucasino/events/" className="eventTemplate__button2" target="blank">
            <FaFacebook />
          </a>
        </div>
      </div>
      <Events />
    </Layout>
  )
}

export default Event
