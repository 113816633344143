import React from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"

// Elements
import Card from "./card"

const Events = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulEventPost(sort: { fields: updatedAt, order: DESC }, limit: 2) {
        edges {
          node {
            title
            slug
            subtitle
            description
            image {
              fluid (maxWidth: 220, maxHeight: 300) {
                src
              }
            }
          }
        }
      }
    }
  `)
  const posts = data.allContentfulEventPost.edges
  return (
    <div className="events">
      {posts.map(post => {
        return (
          <Card
            key={post.node.title}
            image={post.node.image.fluid}
            title={post.node.title}
            subtitle={post.node.subtitle}
            description={post.node.description}
            buttonLink={`/events/${post.node.slug}`}
          />
        )
      })}
    </div>
  )
}

Events.propTypes = {
  children: PropTypes.node,
}


export default Events
