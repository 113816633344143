import React from "react"
import PropTypes from "prop-types"
import { FaFacebookF } from "react-icons/fa"
import { Link } from "gatsby-plugin-intl"
import Img from "gatsby-image"

export default function card(props) {
  const { className, image, title, subtitle, description, buttonText, buttonLink, facebookLink } = props
  return (
    <div className={`card ${className}`}>
      <Img fluid={image} style={{height:"100%", width:"100%", overflow:"none"}} imgStyle={{position:"relative", width:"100%"}}/>
      <div className="card__content">
        <h1 className="card__title">{title}</h1>
        <h3 className="card__subtitle">{subtitle}</h3>
        <p className="card__desc">{description}</p>
        <div className="card__buttons">
          <Link to={buttonLink} className="card__button1">{buttonText}</Link>
          <a href={facebookLink} target="blank" className="card__button2">
            <FaFacebookF />
          </a>
        </div>
      </div>
    </div>
  )
}

card.defaultProps = {
  className: ``,
  title: `Card Title`,
  subtitle: `Card Subtitle`,
  description: `Card Description`,
  buttonText: `Info`,
  buttonLink: `/events`,
  facebookLink: `https://www.facebook.com/pg/newmacaucasino/events/`,
}

card.propTypes = {
  className: PropTypes.string,
  image: PropTypes.object.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  buttonText: PropTypes.string,
  facebookLink: PropTypes.string

}
